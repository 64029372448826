function lxAlert(message, dismissButtonText) {
    if (!message) {
        console.log("Calling lxAlert without message is not permitted")
        return;
    }

    let url = site.uri.public + '/modals/lxAlert?message=' + encodeURI(message)

    if (dismissButtonText) {
        url += '&dismissButtonText=' + dismissButtonText
    }

    $("body").ufModal({
        sourceUrl: url
    });
}
